<footer class="footer-area">
    <app-sponsors></app-sponsors>
    <div class="row text-center mt-4" id="app-download">
        <div class="col-6">
            <a
                target="_blank"
                href="https://apps.apple.com/us/app/split-rock-jumping-tour/id1460292167"
            >
                <img
                    style="width: 80%; max-width: 200px"
                    src="/assets/img/sr/app-store-badge.png"
                    alt=""
            /></a>
        </div>
        <div class="col-6">
            <a
                target="_blank"
                href="https://play.google.com/store/apps/details?id=io.vende.srjt"
                ><img
                    style="width: 80%; max-width: 200px"
                    src="/assets/img/sr/google-play-badge.png"
                    alt=""
            /></a>
        </div>
    </div>
    <div class="container">
        <h3 style="margin-bottom: 0">
            <a routerLink="/"
                ><img width="300" src="/assets/img/sr/2025/srHorizontalDark.png" alt=""
            /></a>
        </h3>
        <p style="line-height: 1.5em; margin-top: 0">
            601 Lagonda Ave, Ste 170<br />
            Lexington, KY 40505<br />
            (859) 252-3247<br />
            info&#64;splitrockjumpingtour.com
        </p>
        <br />
        <ul>
            <li>
                <a
                    href="https://www.facebook.com/SplitRockJumpingTour"
                    target="_blank"
                    class="fab fa-facebook-f"
                ></a>
            </li>

            <li>
                <a
                    href="https://www.youtube.com/@splitrockjumpingtour"
                    target="_blank"
                    class="fab fa-youtube"
                ></a>
            </li>
            <li>
                <a
                    href="https://www.instagram.com/splitrockjumpingtour/"
                    target="_blank"
                    class="fab fa-instagram"
                ></a>
            </li>
        </ul>
        <p>
            Technology by
            <a href="https://equestrian.digital/" target="_blank"
                >Equestrian Digital</a
            >
        </p>
    </div>
</footer>

<ngx-scrolltop></ngx-scrolltop>
