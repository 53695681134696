<div class="location-guide-container">
  <div class="banner-section" [ngStyle]="{'background-image': bannerImage ? 'url(' + bannerImage + ')' : 'none'}">
    <div class="banner-overlay">
      <div class="container">
        <div class="section-header">
          <h2 class="section-title">{{locationTitle}} <span>Guide</span></h2>
          <p class="section-subtitle">{{locationSubtitle}}</p>
        </div>
      </div>
    </div>
  </div>

  <div class="container main-content">
    <div *ngIf="isLoading" class="loading-indicator">
      <div class="spinner"></div>
      <p>Loading location guide...</p>
    </div>

    <div *ngIf="error" class="error-message">
      <p>{{error}}</p>
    </div>
    
    <!-- Intro Text Section -->
    <div *ngIf="introText && !isLoading && !error" class="intro-text-section">
      <div class="intro-content" [innerHtml]="introText"></div>
    </div>
    
    <div *ngIf="!isLoading && !error && locationGuides.length > 0" class="category-tabs">
      <ul class="tab-navigation">
        <li class="tab-item" [ngClass]="{'active': currentTab === 'all'}">
          <span (click)="switchTab($event, 'all')">
            <i class="fas fa-globe-americas"></i> All
          </span>
        </li>
        <li class="tab-item" [ngClass]="{'active': currentTab === 'hotels'}">
          <span (click)="switchTab($event, 'hotels')">
            <i class="fas fa-hotel"></i> Hotels
          </span>
        </li>
        <li class="tab-item" [ngClass]="{'active': currentTab === 'restaurants'}">
          <span (click)="switchTab($event, 'restaurants')">
            <i class="fas fa-utensils"></i> Restaurants
          </span>
        </li>
        <li class="tab-item" [ngClass]="{'active': currentTab === 'rentals'}">
          <span (click)="switchTab($event, 'rentals')">
            <i class="fas fa-car"></i> Rentals
          </span>
        </li>
        <li class="tab-item" [ngClass]="{'active': currentTab === 'services'}">
          <span (click)="switchTab($event, 'services')">
            <i class="fas fa-concierge-bell"></i> Services
          </span>
        </li>
      </ul>
      
      <div class="tab-content">
        <div class="locations-grid">
          <ng-container *ngFor="let location of locationGuides">
            <div class="location-item" *ngIf="location.categoria == currentTab || currentTab == 'all'">
              <div class="location-card">
                <div class="card-image">
                  <img [src]="location.image" [alt]="location.name">
                </div>
                <div class="card-content">
                  <h3 class="location-name">{{location.name}}</h3>
                  <div class="location-details">
                    <p *ngIf="location.address1" class="address">
                      <i class="fas fa-map-marker-alt"></i> {{location.address1}}
                    </p>
                    <p *ngIf="location.address2" class="address">{{location.address2}}</p>
                    <p *ngIf="location.telephone" class="phone">
                      <i class="fas fa-phone"></i> {{location.telephone}}
                    </p>
                  </div>
                  <a *ngIf="location.url" [href]="'//'+location.url" class="website-btn" target="_blank">
                    Visit Website <i class="fas fa-external-link-alt"></i>
                  </a>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </div>

    <div *ngIf="!isLoading && !error && locationGuides.length === 0" class="no-results">
      <p>No locations found for this guide.</p>
    </div>
  </div>
</div> 