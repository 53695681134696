<div class="partnerships-container">
    <div class="container">
        <h1 class="section-title">Our Partnerships</h1>
        <p class="section-subtitle">Explore our exclusive partnership opportunities and event information</p>
        
        <div class="partnership-cards">
            <div class="partnership-card">
                <div class="card-content">
                    <i class="fas fa-trophy card-icon"></i>
                    <h3>FEI WORLD CUP<sup>TM</sup> FINALS</h3>
                    <p>Fort Worth 2026</p>
                    <a href="https://heyzine.com/flip-book/779245dd94.html" target="_blank" class="partnership-link">
                        Learn More
                        <i class="fas fa-arrow-right"></i>      
                    </a>
                </div>
            </div>

            <div class="partnership-card">
                <div class="card-content">
                    <i class="fas fa-handshake card-icon"></i>
                    <h3>Split Rock Jumping Tour</h3>
                    <p>2025 Partners Program</p>
                    <a href="https://heyzine.com/flip-book/6e19edd43b.html" target="_blank" class="partnership-link">
                        Learn More
                        <i class="fas fa-arrow-right"></i>
                    </a>
                </div>
            </div>

            <!-- <div class="partnership-card">
                <div class="card-content">
                    <i class="fas fa-store card-icon"></i>
                    <h3>Split Rock Jumping Tour</h3>
                    <p>2025 Vendor Opportunities</p>
                    <a href="https://heyzine.com/flip-book/6e19edd43b.html" target="_blank" class="partnership-link">
                        Learn More
                        <i class="fas fa-arrow-right"></i>
                    </a>
                </div>
            </div> -->
        </div>
    </div>
</div>
