import { Component } from '@angular/core';

@Component({
  selector: 'app-partnerships',
  standalone: true,
  imports: [],
  templateUrl: './partnerships.component.html',
  styleUrl: './partnerships.component.scss'
})
export class PartnershipsComponent {

}
