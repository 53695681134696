<div class="main-banner item-bg-one">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="main-banner-text">
                    <h1><span>5</span> Cities</h1>
                    <h1><span>10</span> Shows</h1>
                    <h1><span>1</span> Championship</h1>
                </div>
            </div>
        </div>
    </div>
</div>

<app-calendar year="2025"></app-calendar>
<app-spotlight></app-spotlight>
