import { Component } from "@angular/core";

@Component({
  selector: "app-forms",
  templateUrl: "./forms.component.html",
  styleUrl: "./forms.component.scss",
})
export class FormsComponent {
  public files = [
   
    {
      name: "Photo",
      link: "https://firebasestorage.googleapis.com/v0/b/srjt/o/2025%20Forms%2Fphoto%20form%202025%20update%20(1).pdf?alt=media&token=15d348b4-4827-4a26-9ca0-9868d144f6d5",
    },
    {
      name: "Vendor",
      link: "https://firebasestorage.googleapis.com/v0/b/srjt/o/2025%20Forms%2FVendor%20form%202025update.pdf?alt=media&token=bea408e2-8312-4f9e-8462-2417440038a0",
    },
    {
      name: "Sponsor",
      link: "https://firebasestorage.googleapis.com/v0/b/srjt/o/2025%20Forms%2F2025%20Sponsor%20Deck.pdf?alt=media&token=c3071668-4d43-4f80-8820-558f4125d1bf",
    },
    // {
    //   name: "Deposit & Cancellation Policy",
    //   link: "https://firebasestorage.googleapis.com/v0/b/srjt/o/website%2FSplit%20Rock%20Jumping%20Tour%20Deposit%20and%20Cancellation%20Policy.pdf?alt=media&token=e74accd3-907e-4a1e-a68e-7709fab5d307",
    // },
  ];
}
