<div class="editor-container">
  <div class="container">
    <div class="editor-header">
      <h2>Edit Location Guide</h2>
      <p>Make changes to your location guide information and listings.</p>
    </div>

    <div *ngIf="isLoading" class="loading-indicator">
      <div class="spinner"></div>
      <p>Loading location guide...</p>
    </div>

    <div *ngIf="error" class="error-message">
      <p>{{ error }}</p>
    </div>

    <div *ngIf="saveSuccess" class="success-message">
      <p><i class="fas fa-check-circle"></i> Location guide saved successfully!</p>
    </div>

    <form [formGroup]="locationGuideForm" (ngSubmit)="saveLocationGuide()" *ngIf="!isLoading && !error">
      <div class="form-section">
        <div class="d-flex justify-content-between align-items-center">
          <h3>Guide Information</h3>
          <button type="submit" class="btn btn-primary" [disabled]="isSaving">
            <i class="fas fa-save"></i> {{ isSaving ? 'Saving...' : 'Save Guide' }}
          </button>
        </div>
        
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label for="title">Title*</label>
              <input 
                type="text" 
                id="title" 
                formControlName="title" 
                class="form-control" 
                [ngClass]="{'is-invalid': title.touched && title.invalid}"
              >
              <div *ngIf="title.touched && title.invalid" class="invalid-feedback">
                Title is required
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label for="subtitle">Subtitle</label>
              <input 
                type="text" 
                id="subtitle" 
                formControlName="subtitle" 
                class="form-control"
              >
            </div>
          </div>
        </div>
        
        <div class="form-group">
          <div class="row">
            <div class="col-md-8">
              <label for="banner">Banner Image URL</label>
              <input 
                type="text" 
                id="banner" 
                formControlName="banner" 
                class="form-control"
                placeholder="https://example.com/your-banner-image.jpg"
              >
              <small class="form-text text-muted">Provide a URL to an image for the guide's banner background</small>
            </div>
            <div class="col-md-4" *ngIf="banner.value">
              <label>Preview</label>
              <div class="image-preview banner-preview">
                <img [src]="banner.value" alt="Banner Preview">
              </div>
            </div>
          </div>
        </div>
        
        <div class="form-group">
          <label for="introText">Introduction Text (Rich Text Editor)</label>
          <quill-editor 
            formControlName="introText" 
            [styles]="{height: '200px'}"
            [modules]="quillModules"
            placeholder="Enter your introduction text here...">
          </quill-editor>
          <small class="form-text text-muted">Format your content using the toolbar above</small>
          
          <div *ngIf="introText.value" class="html-preview">
            <h4>Preview</h4>
            <div class="preview-content" [innerHTML]="introText.value"></div>
          </div>
        </div>
      </div>
      
      <div class="form-section locations-section">
        <div class="section-header">
          <h3>Locations</h3>
          <div>
            <small class="reorder-instruction"><i class="fas fa-info-circle"></i> Drag items to reorder locations</small>
            <button type="button" class="btn btn-secondary" (click)="addLocation()">
              <i class="fas fa-plus"></i> Add Location
            </button>
          </div>
        </div>
        
        <div formArrayName="locations" cdkDropList (cdkDropListDropped)="onDragDrop($event)">
          <div *ngFor="let locationForm of locations.controls; let i = index" class="location-item" cdkDrag>
            <div class="drag-handle" cdkDragHandle>
              <i class="fas fa-grip-lines"></i>
            </div>
            <div [formGroupName]="i" class="location-form">
              <div class="row">
                <div class="col-md-3" *ngIf="locationForm.get('image')?.value">
                  <div class="location-image-preview">
                    <img [src]="locationForm.get('image')?.value" alt="Location Image">
                  </div>
                </div>
                
                <div [class]="locationForm.get('image')?.value ? 'col-md-9' : 'col-md-12'">
                  <div class="location-header">
                    <h4>Location #{{ i + 1 }}</h4>
                    <button type="button" class="btn btn-danger btn-sm" (click)="removeLocation(i)">
                      <i class="fas fa-trash"></i> Remove
                    </button>
                  </div>
                  
                  <div class="row">
                    <div class="col-md-6">
                      <div class="form-group">
                        <label>Name*</label>
                        <input 
                          type="text" 
                          formControlName="name" 
                          class="form-control" 
                          [ngClass]="{'is-invalid': locationForm.get('name')?.touched && locationForm.get('name')?.invalid}"
                        >
                        <div *ngIf="locationForm.get('name')?.touched && locationForm.get('name')?.invalid" class="invalid-feedback">
                          Name is required
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <label>Category*</label>
                        <select 
                          formControlName="categoria" 
                          class="form-control"
                          [ngClass]="{'is-invalid': locationForm.get('categoria')?.touched && locationForm.get('categoria')?.invalid}"
                        >
                          <option *ngFor="let category of categories" [value]="category.value">
                            {{ category.label }}
                          </option>
                        </select>
                        <div *ngIf="locationForm.get('categoria')?.touched && locationForm.get('categoria')?.invalid" class="invalid-feedback">
                          Category is required
                        </div>
                      </div>
                    </div>
                  </div>
                  
                  <div class="row">
                    <div class="col-md-6">
                      <div class="form-group">
                        <label>Address Line 1</label>
                        <input type="text" formControlName="address1" class="form-control">
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <label>Address Line 2</label>
                        <input type="text" formControlName="address2" class="form-control">
                      </div>
                    </div>
                  </div>
                  
                  <div class="row">
                    <div class="col-md-4">
                      <div class="form-group">
                        <label>Telephone</label>
                        <input type="text" formControlName="telephone" class="form-control">
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group">
                        <label>Website URL</label>
                        <input 
                          type="text" 
                          formControlName="url" 
                          class="form-control"
                          placeholder="example.com"
                        >
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group">
                        <label>Image URL</label>
                        <input 
                          type="text" 
                          formControlName="image" 
                          class="form-control"
                          placeholder="https://example.com/image.jpg"
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          
          <div *ngIf="locations.length === 0" class="no-items-message">
            <p>No locations added yet. Click "Add Location" to add a new location.</p>
          </div>
        </div>
      </div>
      
      <div class="form-actions">
        <button type="button" class="btn btn-light" (click)="cancelEdit()">
          <i class="fas fa-times"></i> Cancel
        </button>
        <button type="button" class="btn btn-secondary" (click)="previewGuide()">
          <i class="fas fa-eye"></i> Preview
        </button>
        <button type="submit" class="btn btn-primary" [disabled]="isSaving">
          <i class="fas fa-save"></i> {{ isSaving ? 'Saving...' : 'Save Guide' }}
        </button>
      </div>
    </form>
  </div>
</div>
