import { Component, OnInit } from "@angular/core";
import { OwlOptions } from "ngx-owl-carousel-o";
import { ViewportScroller } from "@angular/common";
import { AngularFirestore } from "@angular/fire/compat/firestore";
import { Sponsor } from "src/interfaces/sponsor.interface";

@Component({
  selector: "app-sponsors",
  templateUrl: "./sponsors.component.html",
  styleUrls: ["./sponsors.component.scss"],
})
export class SponsorsComponent implements OnInit {
  public sponsors: Array<Sponsor> = []

  constructor(private viewportScroller: ViewportScroller, private firestore: AngularFirestore) { }

  public onClick(elementId: string): void {
    this.viewportScroller.scrollToAnchor(elementId);
  }

  ngOnInit() {
    this.getSponsors();
  }

  public getSponsors() {
   // console.log("Getting sponsors");
    this.firestore.collection('srjt/sponsors/general', ref => ref
      .orderBy('order')
    ).valueChanges().subscribe(
      sponsors => {
       // console.log(sponsors);
        this.sponsors = sponsors as Array<Sponsor>;
      }
    );
  }

  partnerSlides: OwlOptions = {
    loop: true,
    nav: true,
    dots: true,
    autoplayHoverPause: true,
    autoplay: true,
    margin: 30,

    navText: [
      "<i class='fa fa-angle-left'></i>",
      "<i class='fa fa-angle-right'></i>",
    ],
    responsive: {
      0: {
        items: 2,
      },
      576: {
        items: 3,
      },
      768: {
        items: 4,
      },
      1200: {
        items: 5,
      },
    },
  };
}
