import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

interface LocationGuideItem {
  name: string;
  address1?: string;
  address2?: string;
  telephone?: string;
  url?: string;
  image?: string;
  categoria: string;
}

interface LocationGuideDocument {
  title?: string;
  subtitle?: string;
  banner?: string;
  introText?: string;
  locations: LocationGuideItem[];
}

@Component({
  selector: 'app-location-guides',
  templateUrl: './location-guides.component.html',
  styleUrls: ['./location-guides.component.scss']
})
export class LocationGuidesComponent implements OnInit {
  locationGuides: LocationGuideItem[] = [];
  currentTab: string = 'all';
  locationTitle: string = 'Location Guide';
  locationSubtitle: string = 'Discover the best places during your stay';
  bannerImage: string | null = null;
  introText: string | null = null;
  isLoading: boolean = true;
  error: string | null = null;
  currentLocationId: string = '';

  constructor(
    private route: ActivatedRoute,
    private firestore: AngularFirestore
  ) { }

  ngOnInit() {
    this.route.params.subscribe(params => {
      const locationId = params['locationId'];
      this.currentLocationId = locationId;
      if (locationId) {
        this.loadLocationGuide(locationId);
      } else {
        this.error = 'No location specified';
        this.isLoading = false;
      }
    });
  }

  loadLocationGuide(locationId: string) {
    this.isLoading = true;
    this.error = null;
    
    this.firestore.doc<LocationGuideDocument>(`locationGuides/${locationId}`)
      .valueChanges()
      .subscribe(
        (data) => {
          if (data) {
            this.locationTitle = data.title || 'Location Guide';
            this.locationSubtitle = data.subtitle || 'Discover the best places during your stay';
            this.bannerImage = data.banner || null;
            this.introText = data.introText || null;
            this.locationGuides = data.locations || [];
            this.isLoading = false;
          } else {
            this.error = `Location guide for ${locationId} not found`;
            this.isLoading = false;
          }
        },
        (error) => {
          console.error('Error fetching location guide:', error);
          this.error = 'Error loading location guide. Please try again later.';
          this.isLoading = false;
        }
      );
  }

  switchTab(event: Event, category: string) {
    event.preventDefault();
    this.currentTab = category;
  }
} 