<div style="padding-top: 20px; background-color: black;">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-4 col-sm-12" >
            <a href="/show/us_520">
                <img src="https://firebasestorage.googleapis.com/v0/b/ecuestredigital/o/portada%2F516?alt=media&token=efdc67bf-f1df-4dea-92c1-aac4db336633" alt="">
                <div class="showInfoBanner" style="font-family: 'Copperplate';" >
                    Terranova Winter Series Finale CSI2*
                    <span style="float: right;">March 12 - 16</span>
                </div>
            </a>
            </div>
            <ng-container *ngFor="let show of shows | async">

                <div class="col-lg-4 col-sm-12" *ngIf="show.banner" >
<!--                    <div class="single-box" style="position: relative; padding: 1px; width: 98%;" >-->
<!--                        <br>-->
<!--                        <a href="/show/{{show.id}}">-->
<!--                            <div>-->
<!--                                <img src="{{show.banner}}" alt="">-->
<!--                            </div>-->
<!--                            <div class="showInfoBanner" style="font-family: 'Copperplate';" >-->
<!--                                {{show.nombre}} -->
<!--                                <span style="float: right;">{{show.inicio | date:'LLL dd' }} - {{show.fin | date:'dd' }}</span>-->
<!--                            </div>-->
<!--                        </a>-->
<!--                    </div>-->

                    <a href="/show/{{show.id}}">
                        <img src="{{show.banner}}" alt="">
                        <div class="showInfoBanner" style="font-family: 'Copperplate';" >
                            {{show.nombre}}
                            <span style="float: right;">{{show.inicio | date:'LLL dd' }} - {{show.fin | date:'dd' }}</span>
                        </div>
                    </a>

                </div>
            </ng-container>
        </div>
    </div>
</div>
