<div class="sponsors-container">
    <!-- Main Sponsor Banner -->
    <div class="main-sponsor-banner">
        <h1 class="section-title">Thank You to Our Sponsors</h1>
        <div class="primary-sponsor">
            <a href="https://www.jarvisinsurance.com/" target="_blank" class="sponsor-link">
                <img width="500" src="/assets/img/sponsors/jarvisHorizontal.png" alt="Jarvis Insurance" />
            </a>
        </div>
        <!-- Uncomment when Lugano is active -->
        <!--
        <div class="primary-sponsor">
            <a href="https://luganodiamonds.com/" target="_blank" class="sponsor-link">
                <img width="500" src="/assets/img/sponsors/luganoLogo.png" alt="Lugano Diamonds" />
            </a>
        </div>
        -->
    </div>

    <!-- Supporting Sponsors Grid -->
    <div class="supporting-sponsors">
        <div class="container">
            <h2 class="sponsors-subtitle">Supporting Partners</h2>
            <div class="sponsors-grid">
                <ng-container *ngFor="let sponsor of sponsors">
                    <a [href]="sponsor.url" target="_blank" class="sponsor-card">
                        <img [src]="sponsor.img" [alt]="sponsor.name || 'sponsor'" class="sponsor-image">
                    </a>
                </ng-container>
            </div>
        </div>
    </div>
</div>

<style>
.sponsors-container {
    background-color: white;
    min-height: 100vh;
}

.main-sponsor-banner {
    background: linear-gradient(to bottom, #f8f8f8, #ffffff);
    padding: 60px 20px;
    text-align: center;
    border-bottom: 1px solid #eee;
}

.section-title {
    color: #333;
    font-size: 2.5rem;
    margin-bottom: 2rem;
    font-weight: 600;
}

.primary-sponsor {
    margin: 2rem auto;
    max-width: 800px;
    padding: 2rem;
    background: white;
    border-radius: 15px;
    transition: transform 0.3s ease;
    box-shadow: 0 5px 15px rgba(0,0,0,0.05);
}

.primary-sponsor:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 25px rgba(0,0,0,0.1);
}

.primary-sponsor img {
    max-width: 100%;
    height: auto;
}

.supporting-sponsors {
    padding: 60px 0;
    background: white;
}

.sponsors-subtitle {
    text-align: center;
    color: #333;
    font-size: 2rem;
    margin-bottom: 3rem;
    position: relative;
    padding-bottom: 15px;
}

.sponsors-subtitle::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 80px;
    height: 3px;
    background: gold;
}

.sponsors-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 2rem;
    padding: 0 20px;
}

.sponsor-card {
    background: white;
    padding: 1.5rem;
    border-radius: 10px;
    box-shadow: 0 5px 15px rgba(0,0,0,0.05);
    transition: all 0.3s ease;
    display: flex;
    align-items: center;
    justify-content: center;
}

.sponsor-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 25px rgba(0,0,0,0.1);
}

.sponsor-image {
    max-width: 100%;
    height: 200px;
    object-fit: contain;
    filter: grayscale(50%);
    transition: filter 0.3s ease;
}

.sponsor-card:hover .sponsor-image {
    filter: grayscale(0%);
}

@media (max-width: 768px) {
    .section-title {
        font-size: 2rem;
    }

    .sponsors-subtitle {
        font-size: 1.75rem;
    }

    .primary-sponsor {
        padding: 1rem;
    }

    .sponsors-grid {
        grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
        gap: 1.5rem;
    }
}
</style>
