import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators, ReactiveFormsModule } from '@angular/forms';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { AngularFirestoreModule, AngularFirestore } from '@angular/fire/compat/firestore';
import { DragDropModule, CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { QuillModule, QuillEditorComponent } from 'ngx-quill';

// Keep the interfaces
interface LocationGuideItem {
  name: string;
  address1?: string;
  address2?: string;
  telephone?: string;
  url?: string;
  image?: string;
  categoria: string;
}

interface LocationGuideDocument {
  title?: string;
  subtitle?: string;
  banner?: string;
  introText?: string;
  locations: LocationGuideItem[];
}

@Component({
  selector: 'app-location-guide-editor',
  standalone: true,
  imports: [
    CommonModule, 
    ReactiveFormsModule, 
    RouterModule,
    AngularFirestoreModule,
    DragDropModule,
    QuillModule
  ],
  templateUrl: './location-guide-editor.component.html',
  styleUrl: './location-guide-editor.component.scss'
})
export class LocationGuideEditorComponent implements OnInit, AfterViewInit {
  @ViewChild(QuillEditorComponent) quillEditor?: QuillEditorComponent;
  locationGuideForm: FormGroup;
  locationId: string = '';
  isLoading = true;
  isSaving = false;
  error: string | null = null;
  saveSuccess = false;
  diagnosticOutput: string = '';
  
  // Configuration for the Quill editor
  quillModules = {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'],
      ['blockquote', 'code-block'],
      [{ 'header': 1 }, { 'header': 2 }],
      [{ 'list': 'ordered'}, { 'list': 'bullet' }],
      [{ 'script': 'sub'}, { 'script': 'super' }],
      [{ 'indent': '-1'}, { 'indent': '+1' }],
      [{ 'direction': 'rtl' }],
      [{ 'size': ['small', false, 'large', 'huge'] }],
      [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
      [{ 'color': [] }, { 'background': [] }],
      [{ 'font': [] }],
      [{ 'align': [] }],
      ['clean'],
      ['link', 'image', 'video']
    ]
  };
  
  // Categories for dropdown
  categories = [
    { value: 'hotels', label: 'Hotels', icon: 'fa-hotel' },
    { value: 'restaurants', label: 'Restaurants', icon: 'fa-utensils' },
    { value: 'rentals', label: 'Rentals', icon: 'fa-car' },
    { value: 'services', label: 'Services', icon: 'fa-concierge-bell' }
  ];

  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private firestore: AngularFirestore
  ) {
    // Initialize the form
    this.locationGuideForm = this.fb.group({
      title: ['', Validators.required],
      subtitle: [''],
      banner: [''],
      introText: [''],
      locations: this.fb.array([])
    });
  }

  ngOnInit(): void {
    console.log('Editor component initialized');
    this.route.params.subscribe(params => {
      this.locationId = params['locationId'];
      console.log('Location ID in editor:', this.locationId);
      if (this.locationId) {
        this.loadLocationGuide(this.locationId);
      } else {
        this.isLoading = false;
        this.error = 'No location ID specified';
      }
    });
  }

  ngAfterViewInit(): void {
    // After the view is initialized, update the Quill editor content
    setTimeout(() => {
      this.updateQuillEditor();
      
      // Add a listener to the form control to update the editor when the value changes
      this.introText.valueChanges.subscribe(value => {
        console.log('introText value changed:', value);
        // Only update if the editor and form control are out of sync
        if (this.quillEditor && this.quillEditor.quillEditor) {
          const editorText = this.quillEditor.quillEditor.root.innerHTML;
          if (value && value !== editorText) {
            this.updateQuillEditor();
          }
        }
      });
    }, 200);
  }

  // Getters for form controls
  get title() { return this.locationGuideForm.get('title')!; }
  get subtitle() { return this.locationGuideForm.get('subtitle')!; }
  get banner() { return this.locationGuideForm.get('banner')!; }
  get introText() { return this.locationGuideForm.get('introText')!; }
  get locations() { return this.locationGuideForm.get('locations') as FormArray; }

  loadLocationGuide(locationId: string) {
    this.isLoading = true;
    this.error = null;
    
    this.firestore.doc<LocationGuideDocument>(`locationGuides/${locationId}`)
      .valueChanges()
      .subscribe(
        (data) => {
          if (data) {
            console.log('Loaded data from Firestore:', data);
            
            // Patch the form with the loaded data
            this.locationGuideForm.patchValue({
              title: data.title || '',
              subtitle: data.subtitle || '',
              banner: data.banner || '',
              introText: data.introText || ''
            });
            
            // Clear the existing locations array
            while (this.locations.length !== 0) {
              this.locations.removeAt(0);
            }
            
            // Add each location to the form array
            if (data.locations && data.locations.length > 0) {
              data.locations.forEach(location => {
                this.locations.push(this.createLocationFormGroup(location));
              });
            }
            
            // Force the editor to update with multiple attempts
            // First attempt - immediate
            this.updateQuillEditor();
            
            // Second attempt - after a short delay
            setTimeout(() => this.updateQuillEditor(), 100);
            
            // Third attempt - after Angular change detection
            setTimeout(() => this.updateQuillEditor(), 500);
            
            this.isLoading = false;
          } else {
            this.error = `Location guide for ${locationId} not found`;
            this.isLoading = false;
          }
        },
        (error) => {
          console.error('Error fetching location guide:', error);
          this.error = 'Error loading location guide. Please try again later.';
          this.isLoading = false;
        }
      );
  }

  createLocationFormGroup(location?: LocationGuideItem): FormGroup {
    return this.fb.group({
      name: [location?.name || '', Validators.required],
      address1: [location?.address1 || ''],
      address2: [location?.address2 || ''],
      telephone: [location?.telephone || ''],
      url: [location?.url || ''],
      image: [location?.image || ''],
      categoria: [location?.categoria || 'hotels', Validators.required]
    });
  }

  addLocation() {
    this.locations.push(this.createLocationFormGroup());
  }

  removeLocation(index: number) {
    this.locations.removeAt(index);
  }

  async saveLocationGuide() {
    if (this.locationGuideForm.invalid) {
      // Mark all fields as touched to trigger validation
      this.markFormGroupTouched(this.locationGuideForm);
      return;
    }

    this.isSaving = true;
    this.error = null;
    this.saveSuccess = false;

    // Create a new formData object to ensure we're capturing the current state with ordered items
    const formData = {
      title: this.title.value,
      subtitle: this.subtitle.value,
      banner: this.banner.value,
      introText: this.introText.value,
      // Get the current ordered locations from the form array
      locations: this.locations.controls.map(control => control.value)
    };
    
    try {
      // Set the entire document with the new ordered data
      await this.firestore.doc(`locationGuides/${this.locationId}`).set(formData);
      
      this.isSaving = false;
      this.saveSuccess = true;
      
      // Reset success message after 3 seconds
      setTimeout(() => {
        this.saveSuccess = false;
      }, 3000);
    } catch (error) {
      console.error('Error saving location guide:', error);
      this.error = 'Error saving location guide. Please try again.';
      this.isSaving = false;
    }
  }

  // Utility method to mark all controls in a form group as touched
  markFormGroupTouched(formGroup: FormGroup) {
    Object.values(formGroup.controls).forEach(control => {
      control.markAsTouched();
      
      if (control instanceof FormGroup) {
        this.markFormGroupTouched(control);
      } else if (control instanceof FormArray) {
        (control as FormArray).controls.forEach(c => {
          if (c instanceof FormGroup) {
            this.markFormGroupTouched(c);
          }
        });
      }
    });
  }

  // Preview the current guide
  previewGuide() {
    this.router.navigate(['/locationGuides', this.locationId]);
  }

  // Cancel editing and go back to the view
  cancelEdit() {
    this.router.navigate(['/locationGuides', this.locationId]);
  }

  // Add method to handle drag-and-drop reordering
  onDragDrop(event: CdkDragDrop<string[]>) {
    const locationControls = this.locations.controls;
    // Move the item in the array
    moveItemInArray(locationControls, event.previousIndex, event.currentIndex);
  }

  // Add a helper method to update the Quill editor
  private updateQuillEditor() {
    if (this.quillEditor && this.quillEditor.quillEditor) {
      const content = this.introText.value;
      console.log('Attempting to update Quill editor with content:', content);
      
      if (content) {
        // Try to set content directly on the Quill instance
        this.quillEditor.quillEditor.setContents([]);
        this.quillEditor.quillEditor.clipboard.dangerouslyPasteHTML(content);
      }
    }
  }
}
